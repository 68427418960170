@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-up($lower, $breakpoints) {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-between($name, $name, $breakpoints) {
    @content;
  }
}

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 480px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1650px,
  // Very Large screen / desktop
  xl: 2400px
) !default;


$spacer:   1em !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: ($spacer-x * 1),
    y: ($spacer-y * 1)
  ),
  2: (
    x: ($spacer-x * 2),
    y: ($spacer-y * 2)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  ),
  4: (
    x: ($spacer-x * 4),
    y: ($spacer-y * 4)
  ),
  5: (
    x: ($spacer-x * 5),
    y: ($spacer-y * 5)
  ),
  6: (
    x: ($spacer-x * 6),
    y: ($spacer-y * 6)
  ),
  7: (
    x: ($spacer-x * 7),
    y: ($spacer-y * 7)
  ),
  8: (
    x: ($spacer-x * 8),
    y: ($spacer-y * 8)
  ),
  9: (
    x: ($spacer-x * 9),
    y: ($spacer-y * 9)
  ),
  10: (
    x: ($spacer-x * 10),
    y: ($spacer-y * 10)
  ),
  11: (
    x: ($spacer-x * 11),
    y: ($spacer-y * 11)
  ),
  12: (
    x: ($spacer-x * 12),
    y: ($spacer-y * 12)
  ),
  13: (
    x: ($spacer-x * 13),
    y: ($spacer-y * 13)
  ),
  14: (
    x: ($spacer-x * 14),
    y: ($spacer-y * 14)
  ),
  15: (
    x: ($spacer-x * 15),
    y: ($spacer-y * 15)
  ),
  16: (
    x: ($spacer-x * 16),
    y: ($spacer-y * 16)
  ),
  17: (
    x: ($spacer-x * 17),
    y: ($spacer-y * 17)
  ),
  18: (
    x: ($spacer-x * 18),
    y: ($spacer-y * 18)
  ),
  19: (
    x: ($spacer-x * 19),
    y: ($spacer-y * 19)
  ),
  20: (
    x: ($spacer-x * 20),
    y: ($spacer-y * 20)
  )
) !default;


@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $lengths in $spacers {
        $length-x: map-get($lengths, x);
        $length-y: map-get($lengths, y);

        .#{$abbrev}#{$infix}-#{$size}  { #{$prop}:        $length-y $length-x !important; }
        .#{$abbrev}t#{$infix}-#{$size} { #{$prop}-top:    $length-y !important; }
        .#{$abbrev}r#{$infix}-#{$size} { #{$prop}-right:  $length-x !important; }
        .#{$abbrev}b#{$infix}-#{$size} { #{$prop}-bottom: $length-y !important; }
        .#{$abbrev}l#{$infix}-#{$size} { #{$prop}-left:   $length-x !important; }
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length-x !important;
          #{$prop}-left:  $length-x !important;
        }
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top:    $length-y !important;
          #{$prop}-bottom: $length-y !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto  { margin:        auto !important; }
    .mt#{$infix}-auto { margin-top:    auto !important; }
    .mr#{$infix}-auto { margin-right:  auto !important; }
    .mb#{$infix}-auto { margin-bottom: auto !important; }
    .ml#{$infix}-auto { margin-left:   auto !important; }
    .mx#{$infix}-auto {
      margin-right: auto !important;
      margin-left:  auto !important;
    }
    .my#{$infix}-auto {
      margin-top:    auto !important;
      margin-bottom: auto !important;
    }
  }
}

