@import 'bootstrap-variables';
@import 'font-awesome-variables';
@import 'spacers';
@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../../bower_components/font-awesome/scss/font-awesome';
@import '../../bower_components/Yamm3/yamm/yamm.scss';
@import '../../bower_components/owl.carousel/src/scss/owl.carousel';
@import '../../bower_components/owl.carousel/src/scss/owl.theme.default';
// FONTS
$web-font-path: "https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto:400,400i,700,700i" !default;
@import url($web-font-path);
// SASS FUNCTION
@function em($px, $base: $font-size-base) {
    @return ($px / $base) * 1em;
}

// VARS
$brand-primary: #eb212d;
$brand-secondary: #172B6D;
$col-lgray: #F5F5F5;
$col-mgray: #E5E5E5;
$col-gray: #525155;
$col-dgray: #525155;
// LAYOUT
.bg-gray {
    background-color: #F7F7F7;
}

.bg-lgray {
    background-color: $col-lgray;
}

.bg-mgray {
    background-color: $col-mgray;
}

section.section-fluid {
    @media (min-width: $screen-sm-min) {
        padding-left: 3em;
        padding-right: 3em;
    }
    @media (min-width: $screen-md-min) {
        padding-left: 1em;
        padding-right: 1em;
    }
    @media (min-width: 1490px) {
        padding-left: 3em;
        padding-right: 3em;
    }
    @media (min-width: 1810px) {
        padding-left: 12em;
        padding-right: 12em;
    }
}

.disp-inbl {
    display: inline-block;
}

.pos-rel {
    position: relative;
}

.masonry-sizer,
.masonry-item {
    width: 100%;
    @media (min-width: $screen-md-min) {
        width: 49%;
    }
}

.masonry-item {
    margin-bottom: 2%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @media (min-width: $screen-md-min) {
        height: 300px;
    }
    .masonry-item-content {
        float: left;
        display: table;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        .masonry-item-content-img,
        .masonry-item-content-txt {
            display: table-cell;
            vertical-align: middle;
        }
        .masonry-item-content-img {
            width: 33%;
            &.masonry-img-left {
                padding-left: 1em;
            }
            &.masonry-img-right {
                padding-right: 1em;
            }
        }
        .masonry-item-content-txt {
            width: 66%;
            padding-right: 1em;
            padding-left: 1em;
            font-family: 'Roboto', sans-serif;
            color: #FFF;
            font-weight: 700;
            @media (min-width: $screen-md-min) {
                padding-right: 3em;
                padding-left: 3em;
            }
            h2,
            h3 {
                color: #FFF;
                font-weight: 700;
            }
            h2 {
                font-size: em(24px);
                @media (min-width: $screen-md-min) {
                    font-size: em(44px);
                }
            }
            h3 {
                font-size: em(20px);
                @media (min-width: $screen-md-min) {
                    font-size: em(30px);
                }
            }
        }
    }
    &.masonry-item--width2 {
        width: 100%;
        .masonry-item-content-img,
        .masonry-item-content-txt {
            width: 50%;
        }
    }
    &.masonry-item--height2 {
        @media (min-width: $screen-md-min) {
            height: 400px;
        }
    }
    &.masonry-item--height3 {
        @media (min-width: $screen-md-min) {
            height: 600px;
        }
    }
}

.gutter-sizer {
    width: 2%;
}

// ELEMENTS
.separator {
    display: block;
    max-width: 40px;
    height: 1px;
    margin: 0 auto .5em auto;
    background-color: #000;
    position: relative;
}

form {
    label {
        font-weight: 400;
        font-size: 80%;
    }
    .form-control {
        &:focus {
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(100, 100, 100, .25);
        }
    }
}

.form-control {
    &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(100, 100, 100, .25);
    }
}

#home-newsletter {
    input {
        border-radius: 25px 0 0 25px;
        border: 1px solid #EAEAEA;
        border-right: 0 none;
        box-shadow: 0 0 10px rgba(50, 50, 50, .14);
        &:focus,
        &:active {
            box-shadow: 0 0 10px rgba(50, 50, 50, .14);
            background-color: $col-dgray;
            color: #FFF;
        }
    }
    .btn {
        padding: 16.5px 32px;
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 700;
        color: #000;
        text-transform: none;
        border-radius: 0 25px 25px 0;
        border: 1px solid #EAEAEA;
        box-shadow: 0 0 10px rgba(50, 50, 50, .14);
        &:hover {
            background-color: $col-dgray;
            color: #FFF;
        }
        &:focus,
        &:active {
            outline-color: transparent;
        }
    }
}

#prod-list .utilizzi-top {
    width: 100%;
    float: left;
    .box-utilizzi {
        width: calc((100% - 3em) / 4);
        max-width: 110px;
        display: inline-block;
        background-color: #FAFAFA;
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        float: left;
        height: 0;
        padding-bottom: calc((100% - 3em) / 4);
        position: relative;
        margin: .25em .25em .25em 0;
        font-size: em(14px);
        &:first-of-type {
            margin-left: 0;
        }
        &:last-of-type {
            margin-right: 0;
        }
        &:hover {
            background-color: #E7E7E7;
            cursor: pointer;
        }
        @media (min-width: $screen-sm-min) {
            width: calc((100% - 3em) / 7);
            padding-bottom: calc((100% - 3em) / 7);
        }
        img {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

#prod-detail .utilizzi-top {
    width: 100%;
    float: left;
    .box-utilizzi {
        width: calc((100% - 3em) / 3);
        max-width: 110px;
        display: inline-block;
        background-color: #FAFAFA;
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        float: left;
        height: 0;
        padding-bottom: calc((100% - 3em) / 3);
        position: relative;
        margin: 0.25em;
        &:first-of-type {
            margin-left: 0;
        }
        &:last-of-type {
            margin-right: 0;
        }
        &:hover {
            background-color: #E7E7E7;
            cursor: pointer;
        }
        img {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.table {
    td {
        font-size: em(14px);
        vertical-align: middle !important;
    }
    >tbody>tr>th,
    .table>thead>tr>th {
        color: $col-dgray;
        vertical-align: middle !important;
    }
    &.cart {
        text-align: center;
        >thead>tr {
            >td,
            >th {
                border-bottom-width: 1px;
                color: $col-dgray;
            }
        }
        th {
            text-align: center;
            font-size: em(14px);
        }
        .product-remove a i {
            color: $col-dgray;
        }
        .product-quantity input {
            text-align: center !important;
        }
        .actions {
            .coupon {
                float: left;
            }
        }
    }
    &.cart-total {
        td,
        th {
            padding: 12px;
        }
        tbody>tr>th {
            background-color: #F8F8F8;
            font-size: em(14px);
            width: 50%;
        }
    }
}

#cart {
    h2 {
        @media (min-width: $screen-md-min) {
            text-align: right;
        }
    }
    a.btn-primary {
        @media (min-width: $screen-md-min) {
            float: right;
        }
    }
}

#checkout {
    .panel-primary {
        border-color: #ddd;
        .panel-heading {
            background-color: #FFF;
            border-color: #ddd;
            color: $brand-primary;
            h4 a {
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    color: #A10100;
                }
            }
        }
        .panel-body {
            border-top-color: #ddd;
        }
    }
}

.news-archive-single {
    .news-img {
        img {
            &:hover {
                opacity: 0.75;
            }
        }
    }
    .news-meta {
        display: block;
        float: left;
        @media (max-width: $screen-sm-max) {
            margin-top: 1em;
        }
        .date {
            display: inline-block;
            float: left;
            margin-right: 0.3em;
            &:after {
                content: ' -';
            }
        }
        .category {
            display: inline-block;
            float: left;
        }
    }
    .news-content {
        display: block;
        a {
            &:hover {
                text-decoration: none;
            }
            h2 {
                margin-top: 12.5px;
                &:hover {
                    color: $brand-primary;
                }
            }
        }
    }
}

.news-single {
    .news-meta {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

// HEADER
.header-intro {
    background-color: $brand-primary;
    text-align: center;
    p {
        margin-bottom: 0;
        font-size: em(14px);
        font-weight: 700;
        letter-spacing: 1px;
        padding: 0.5em 0;
        color: #000;
    }
}

.navbar {
    min-height: 100px;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: $screen-sm-min) {
        padding-left: 3em;
        padding-right: 3em;
    }
    @media (min-width: $screen-md-min) {
        padding-left: 1em;
        padding-right: 1em;
    }
    @media (min-width: 1250px) {
        min-height: 130px;
        padding-left: 0;
        padding-right: 0;
    }
    @media (min-width: 1490px) {
        padding-left: 3em;
        padding-right: 3em;
    }
    @media (min-width: 1810px) {
        padding-left: 12em;
        padding-right: 12em;
    }
}

.navbar-default {
    background-color: #FFF;
    border-color: $col-lgray;
    .navbar-nav {
        @media (max-width: $screen-sm-max) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        >li {
            border-bottom: 2px solid transparent;
            &.nohov {
                &:focus,
                &:hover {
                    border-bottom: 2px solid transparent;
                }
            }
            &:focus,
            &:hover {
                @media (min-width: $screen-md-min) {
                    border-bottom: 2px solid $brand-primary;
                }
            }
            >a {
                text-transform: uppercase;
                font-size: em(12px);
                color: $brand-secondary;
                @media (max-width: $screen-sm-max) {
                    padding-top: 7px;
                    padding-bottom: 7px;
                }
                @media (min-width: $screen-md-min) {
                    font-size: em(16px);
                }
                &:focus,
                &:hover {
                    color: $brand-secondary;
                }
            }
            &.active {
                a,
                a:focus,
                a:hover {
                    color: $brand-secondary;
                    background-color: transparent;
                }
            }
        }
        >.open>a {
            &,
            &:hover,
            &:focus {
                color: $brand-primary;
            }
        }
    }
}

.navbar-toggle {
    margin-top: 30px;
    margin-bottom: 30px;
}

.navbar-brand {
    float: left;
    padding: 25px 15px;
    width: 75%;
    height: 90px;
    @media (min-width: 320px) and (max-width: $screen-sm-max) {
        padding: 15px 15px;
    }
    @media (min-width: $screen-md-min) and (max-width: 1250px) {
        padding: 23px 15px;
        height: auto;
        width: auto;
    }
    @media (min-width: 1250px) {
        padding: 13px 15px;
        height: 90px;
        width: auto;
    }
}

#navbar {
    .navbar-main {
        @media (min-width: $screen-md-min) {
            display: inline-block;
            float: none;
            vertical-align: top;
            text-align: center;
            width: 100%;
        }
        >li {
            @media (min-width: $screen-md-min) {
                float: none;
                display: inline-block;
                margin-top: 1em;
                margin-bottom: 1em;
            }
            &.active {
                border-bottom: 2px solid $brand-primary;
            }
            >a {
                padding: 15px 30px;
            }
        }
        .yamm-content {
            padding: 0.5em 1em;
            .col-lg-4 {
                @media (min-width: 1200px) and (max-width: 1250px) {
                    width: 100%;
                }
            }
            ul {
                a {
                    color: #3D3D3D;
                    font-size: 14px;
                    letter-spacing: 0.25px;
                    &:hover {
                        text-decoration: none;
                    }
                    @media (max-width: 1250px) {
                        font-size: 12px;
                        letter-spacing: 0;
                    }
                    li {
                        background-color: #F2F2F2;
                        padding: 0.25em 0.5em 0.25em 0.25em;
                        margin-bottom: 0.6em;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        &:hover {
                            background-color: #E7E7E7;
                        }
                        img {
                            margin-right: 0.25em;
                        }
                    }
                    &:last-of-type>li {
                        margin-bottom: 0;
                        @media (min-width: 1200px) and (max-width: 1250px) {
                            margin-bottom: 0.6em;
                        }
                    }
                }
            }
        }
    }
}

.navbar-right>li {
    &:not(:first-of-type) {
        margin-left: 20px;
        float: left;
    }
    @media (min-width: $screen-md-min) {
        margin-left: 0;
    }
    @media (max-width: $screen-xs-max) {
        display: inline-block;
    }
    >a {
        padding: 18px 5px;
        float: right;
        @media (min-width: $screen-md-min) {
            float: right;
        }
        @media (min-width: $screen-md-min) and (max-width: 1250px) {
            padding: 26px 5px;
        }
    }
    div.header-cart {
        padding: 10px 5px;
        float: right;
        position: relative;
        @media (min-width: $screen-md-min) and (max-width: 1250px) {
            padding: 32px 5px;
        }
        @media (min-width: 1251px) {
            padding: 23px 5px;
        }
        .header-cart-img {
            background: rgba(0, 0, 0, 0) url('../img/sprite-cart.png') no-repeat scroll center -178px;
            box-sizing: border-box;
            color: #000000;
            cursor: pointer;
            font-size: 14px;
            font-weight: 700;
            height: 42px;
            padding: 0;
            position: relative;
            width: 48px;
            .header-cart-num {
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -khtml-border-radius: 50%;
                -moz-border-radius: 50%;
                background: $brand-primary;
                color: #000;
                display: inline-block;
                font-size: 11px;
                left: auto;
                line-height: 12px;
                min-width: 12px;
                padding: 3px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                vertical-align: top;
            }
        }
    }
}

#header-search {
    &.navbar-form {
        margin: 25px 0;
        @media (max-width: $screen-sm-max) {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 25px;
            padding-right: 25px;
        }
        @media (min-width: $screen-md-min) and (max-width: 1250px) {
            margin-top: 35px;
            margin-bottom: 35px;
        }
    }
    input {
        border-radius: 25px 0 0 25px;
        border: 1px solid #EAEAEA;
        border-right: 0 none;
        box-shadow: 0 0 0 transparent;
        font-size: 12px;
        &:focus,
        &:active {
            box-shadow: 0 0 10px rgba(50, 50, 50, .14);
        }
    }
    .btn {
        padding: 6px 12px;
        font-size: 18px;
        margin: 0;
        text-transform: none;
        border-radius: 0 25px 25px 0;
        border: 1px solid #EAEAEA;
        border-left: 0 none;
        &:focus,
        &:active {
            outline-color: transparent;
        }
    }
}

div.circle-avatar {
    width: 30px;
    height: 30px;
    display: block;
    padding-top: 30px;
    border: 1px solid $col-dgray;
    border-radius: 50%;
    background-position: center center;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    &:hover {
        box-shadow: 0 0 10px rgba(50, 50, 50, .25);
    }
}

hr.header-divider {
    padding: 0;
    margin: 0;
    border: none;
    &:before {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        max-width: 100%;
        width: 100%;
        border: 1px solid #f5f5f5;
    }
}

#breadcrumb-container {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    h1 {
        font-size: em(20px);
        margin: 0;
        line-height: 1.42857;
        text-transform: none;
        span {
            color: $col-dgray;
            font-size: 17px;
            text-transform: uppercase;
            &:after {
                content: " / ";
                padding: 0 5px 0 10px;
            }
        }
    }
    .breadcrumb {
        padding: 0;
        margin: 0;
        @media (min-width: $screen-md-min) {
            text-align: right;
        }
        li {
            text-transform: uppercase;
            font-size: em(12px);
            font-weight: 700;
            color: #172b6d;
            a {
                color: #172b6d;
                &:hover {
                    color: #172b6d;
                }
            }
        }
    }
}

// FOOTER
footer {
    color: #FFF;
    .footer-top {
        font-size: em(14px);
        background-color: $col-dgray;
        h4 {
            color: $brand-primary;
        }
        p,
        a {
            color: #FFF;
            letter-spacing: 0.5px;
        }
        ul {
            li {
                margin-bottom: 0.35em;
            }
        }
    }
    .footer-bottom {
        font-size: em(16px);
        background-color: #3D3D3D;
    }
}

// SIDE NAV
.side-nav-box {
    border: 1px solid #EAEAEA;
    padding: 2em 1em;
    margin-bottom: 3em;
    h6 {
        margin: 0;
        color: $col-dgray;
    }
}

.panel-default {
    border-color: transparent;
    box-shadow: none;
    >.panel-heading {
        background-color: transparent;
        border-color: transparent;
        padding: 5px 0;
        h4 {
            display: inline-block;
            &.panel-title a {
                font-size: em(13px);
                font-weight: 700;
                color: $col-gray;
                &:hover,
                &:active,
                &:focus,
                &.active {
                    text-decoration: none;
                    color: $brand-primary !important;
                }
                &.collapsed {
                    color: $col-gray;
                    &.active {
                        color: $brand-primary;
                    }
                }
            }
            img {
                width: 1.5em;
                height: auto;
                margin-right: 0.25em;
            }
        }
        a.toggle-menu {
            display: inline-block;
            float: right;
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }
    .panel-body {
        padding-left: 1em;
        padding-top: 0;
        ul li {
            a {
                font-size: em(15px);
                font-weight: 700;
                margin-left: 0.35em;
                margin-bottom: 0.5em;
                color: $col-gray;
                &:hover {
                    color: $brand-primary;
                    text-decoration: none;
                }
            }
            &.active {
                a {
                    color: $brand-primary;
                }
            }
        }
    }
}

#box-popular {
    .media-object {
        max-width: 100px;
    }
    .media-heading {
        a {
            font-size: em(12px);
            font-weight: 700;
        }
        p {
            font-size: em(11px);
            line-height: 1.3;
            margin-top: 0.5em;
        }
    }
}

#sidebar-cat {
    ul li {
        padding-left: 1.5em;
        margin-bottom: 0.25em;
        a {
            &:before {
                content: '\f101';
                font-family: 'FontAwesome';
                display: inline-block;
                margin-left: -1.5em;
                width: 1.5em;
            }
        }
    }
}

#side-nav-contacts {
    li {
        margin-bottom: 1em;
        padding-right: 1em;
    }
}

// TYPO
h1,
.h1 {
    text-transform: uppercase;
    color: $brand-secondary;
    font-size: em(36px);
    font-weight: 400;
}

h2,
.h2 {
    font-size: em(32px);
    color: #000;
}

h4,
.h4 {
    color: $brand-secondary;
}

h6,
.h6 {
    text-transform: uppercase;
    color: $brand-primary;
    font-weight: 700;
}

.slash {
    text-transform: uppercase;
    color: $brand-primary;
    font-weight: 700;
    font-size: em(16px);
    margin: 2em 0;
    &:before {
        content: '\005c ';
        color: $brand-secondary;
        padding-right: 0.5em;
    }
}

a {
    color: $brand-secondary;
}

// BUTTONS - CTA
a {
    &:focus,
    &:hover,
    &:active {
        color: $brand-primary;
    }
}

.btn {
    text-transform: uppercase;
    padding: 8px 16px;
    margin: 1em auto;
    font-size: em(12px);
    white-space: normal;
    -webkit-transition: .25s linear;
    -moz-transition: .25s linear;
    -o-transition: .25s linear;
    transition: .25s linear;
    @media (min-width: $screen-md-min) {
        white-space: nowrap;
    }
}

.btn-primary {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
    &:hover,
    &:active,
    &:focus,
    &:active:focus {
        background-color: $brand-primary;
        border-color: $brand-primary;
    }
}

.btn-secondary {
    border: 1px solid #000;
    border-radius: 25px;
    color: $brand-secondary;
    font-weight: 700;
    font-size: em(13px);
    padding: 12px 26px;
    &:hover,
    &:active,
    &:focus {
        background-color: #000;
        color: $brand-primary;
    }
    #masonry & {
        border-color: #FFF;
        color: #FFF;
        font-size: em(16px);
        font-weight: 700;
        text-transform: capitalize;
        padding: 8px 24px;
        @media (min-width: $screen-md-min) {
            padding-left: 40px;
            padding-right: 40px;
        }
        &:hover,
        &:active,
        &:focus {
            background-color: $brand-primary;
            border-color: $brand-primary;
        }
    }
}

// OWL SLIDER
#home-owlslide-main {
    .owl-text-overlay {
        position: relative;
        display: block;
        text-align: left;
        color: $col-dgray;
        padding: 1em;
        @media (min-width: $screen-sm-min) {
            position: absolute;
            text-align: left;
            width: 85%;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            color: $col-dgray;
            &.text-light {
                color: #FFF;
            }
            &.text-dark {
                color: $col-dgray;
            }
        }
        @media (min-width: $screen-md-min) {
            width: 60%;
            margin-left: 1em;
        }
        @media (min-width: 1490px) {
            width: 50%;
            margin-left: 3em;
        }
        @media (min-width: 1810px) {
            width: 45%;
            margin-left: 12em;
        }
        p.owl-caption-top {
            font-size: em(20px);
            font-weight: 400;
            line-height: 1.1em;
            margin-bottom: 0.75em;
            @media (min-width: $screen-sm-min) {
                font-size: em(32px);
            }
            @media (min-width: $screen-lg-min) {
                font-size: em(44px);
            }
        }
        p.owl-caption-bottom {
            font-size: em(28px);
            font-weight: 700;
            line-height: 1.1em;
            @media (min-width: $screen-sm-min) {
                font-size: em(40px);
                font-weight: 400;
            }
            @media (min-width: $screen-lg-min) {
                font-size: em(64px);
            }
        }
    }
}

#home-owlslide-sections {
    .item-owlslide {
        display: table;
        table-layout: fixed;
        clear: both;
        border-bottom: 5px solid $brand-primary;
        background: url('../img/home-owlslide-sections-bg.jpg') no-repeat 50% 50%;
        background-size: cover;
        .img-left-owlslide {
            width: 40%;
            display: table-cell;
            vertical-align: middle;
            padding: 1em;
        }
        .text-right-owlslide {
            width: 60%;
            vertical-align: middle;
            display: table-cell;
            text-align: left;
            padding: 1em 2em 1em 0;
            h3 {
                font-size: em(36px);
                color: #5f5f5f;
            }
        }
    }
}

#home-owlslide-applications {
    .item-owlslide {
        display: table;
        table-layout: fixed;
        clear: both;
        background: #000;
        .text-left-owlslide {
            width: 60%;
            display: table-cell;
            text-align: left;
            vertical-align: middle;
            padding: 0.5em 0 0.5em 2em;
            color: #FFF;
            h3 {
                font-size: em(30px);
                text-transform: uppercase;
            }
            p {
                font-weight: 700;
                font-size: em(13px);
                color: #B8B8B8;
            }
        }
        .img-right-owlslide {
            width: 40%;
            display: table-cell;
            padding: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0% 100%;
        }
    }
}

#home-owlslide-news {
    .item-owlslide {
        text-align: center;
        .intro-news {
            position: relative;
        }
        .date {
            border: 5px solid #FFF;
            background-color: $brand-primary;
            color: #000;
            max-width: 110px;
            font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            bottom: -15%;
            .day {
                font-size: em(40px);
            }
            .month-year {
                font-size: em(14px);
                letter-spacing: 2px;
                padding-bottom: 0.5em;
            }
        }
        .title {
            margin-top: 5em;
            padding: 0 3em;
            h4 {
                color: #000;
                margin-top: 0;
            }
        }
        .abstract {
            padding: 0.5em 2em;
            color: #555;
            font-size: em(14px);
            letter-spacing: 1px;
            line-height: 1.75em;
        }
    }
}

#ec-featured-products {
    .item-owlslide {
        padding: 3em;
    }
}

.owl-theme {
    &#prod-video {
        .owl-nav.disabled+.owl-dots {
            margin-top: 1em;
            text-align: left;
        }
    }
    .owl-nav.disabled+.owl-dots {
        margin-top: 3em;
    }
}

.slider-container {
    position: relative;
    display: block;
    .slider .content img {
        width: 100%;
        height: auto;
    }
    .slider-controls a {
        position: absolute;
        top: 45%;
        z-index: 100;
        color: #333333;
        &.slider-left {
            left: -25px;
        }
        &.slider-right {
            right: -25px;
        }
    }
}

.thumbnail-slider-container {
    margin-top: 5px;
    .owl-stage {
        .owl-item {
            .content {
                padding: 5px;
            }
        }
    }
}

.owl-prev {
    width: 34px;
    height: 34px;
    position: absolute;
    top: 40%;
    left: 0;
    margin-left: -20px;
    display: block !important;
    border: 1px solid black;
    font-size: 24px !important;
    padding: 0 !important;
    color: $col-dgray !important;
    background-color: transparent !important;
    border-radius: 50% !important;
    margin: 0 !important;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }
}

.owl-next {
    width: 34px;
    height: 34px;
    position: absolute;
    top: 40%;
    right: 0;
    display: block !important;
    border: 1px solid black;
    font-size: 24px !important;
    padding: 0 !important;
    color: $col-dgray !important;
    background-color: transparent !important;
    border-radius: 50% !important;
    margin: 0 !important;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }
}

// TABS
.nav-tabs>li {
    vertical-align: middle;
    border-right: 2px solid $col-lgray;
    &:last-of-type {
        border: 0 none;
    }
    >a {
        text-transform: uppercase;
        color: $col-gray;
        font-size: em(18px);
        padding-top: 0;
        padding-bottom: 0;
        &:hover {
            cursor: pointer;
            color: $brand-primary;
        }
    }
    &.active>a,
    &.active>a:active,
    &.active>a:focus {
        color: $brand-secondary;
    }
}

// ITEMS
.item {
    margin: 0 auto;
    overflow: hidden;
    max-width: 180px;
    text-align: center;
}

// NEWS
.news-ec {
    position: relative;
    max-width: 550px;
    margin: 0 auto 2em auto;
    .overnewsec {
        position: absolute;
        overflow: hidden;
        bottom: 10%;
        color: $brand-secondary;
        width: 85%;
        padding: 0.5em 1em;
        font-weight: 700;
        line-height: 1.3em;
        font-family: 'Roboto', sans-serif;
        a {
            font-size: em(18px);
            @media (min-width: $screen-md-min) {
                font-size: em(24px);
            }
            &:hover {
                color: $brand-secondary;
                text-decoration: none;
            }
        }
        &.overnewsec-right {
            right: 0;
            text-align: right;
            background: url('../img/arrow-left.png') no-repeat 5% 50%;
            background-color: $brand-primary;
            padding-left: 2em;
            float: left;
            &:hover {
                background-color: darken($brand-primary, 5%);
            }
        }
        &.overnewsec-left {
            left: 0;
            text-align: left;
            background: url('../img/arrow-right.png') no-repeat 95% 50%;
            background-color: $brand-primary;
            padding-right: 2em;
            float: right;
            &:hover {
                background-color: darken($brand-primary, 5%);
            }
        }
        span {
            display: block;
            color: #FFF;
            font-size: em(12px);
            @media (min-width: $screen-md-min) {
                font-size: em(16px);
            }
        }
    }
}

// BACK TO TOP
#top {
    position: fixed;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background: $brand-primary;
    bottom: 12px;
    right: 20px;
    cursor: pointer;
}

#top #top-arrow {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 5px solid #fff;
    border-left: 5px solid #fff;
    border-bottom: none;
    border-right: none;
    width: 10px;
    height: 10px;
    margin: 15px auto;
}

.img-product {
    height: 84px;
}

.header-contact {
    padding: 0px;
}

.table-price {
    font-size: 13px !important;
    color: #172b6d;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 80px !important;
}

a:focus {
    outline: 0 !important;
}

.home-price {
    color: #eb212d !important;
}

.custom-price {
    color: #172b6d !important;
}

.item-content {
    bottom: 0;
    min-height: 110px;
}

.special_menu_a {
    padding-left: 5px !important;
}